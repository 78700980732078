// 用户新增 编辑
<template>
  <base-dialog
    :visible.sync="visible"
    width="854px"
    :title="editorTitle"
    class="user-dialog"
    @submit="handleSubmit"
  >
    <base-form
      :componentList="componentList"
      :formAttrs="{
        model: formData,
        labelWidth: '140px',
      }"
      class="formStyle"
      :showBtns="false"
      ref="formData"
    >
    </base-form>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import baseForm from '@/components/common/base-form//base-form.vue'
import { editUserFormConfig } from './utils/config.js'
import {
  getOrgConfigsByOrgType,
  updateUserByBiz, createUserByBiz
} from '@/api/sys/user.js'
export default {
  components: { baseForm, BaseDialog },
  props: {},
  data () {
    return {
      model: '',
      visible: false,
      editorRow: '',
      formData: {},
      orgOptions: [],
      preData: {},
      optionSelectedKeys: ['keyId', 'enterpriseName'],
      bankOptions: []
    }
  },
  mounted () {
    this.initData()
  },
  computed: {
    editorTitle () {
      return this.model === 'C' ? '新增用户' : '编辑用户'
    },
    componentList () {
      return editUserFormConfig(this)
    },
    userType () {
      return this.$store.state.user.userInfo?.userType
    }
  },
  methods: {
    open (mode, row) {
      this.model = mode
      this.editorRow = row
      this.visible = true
      this.initData()
      this.registerClear()
    },

    /**
     * 关闭时处理重置数据逻辑
     */
    registerClear () {
      const listen = this.$watch('visible', function watcher () {
        this.formData = {}
        this.$nextTick(() => {
          this.$refs.formData.clearValidate()
        })
        listen()
      })
    },

    async initData () {
      try {
        if (this.model === 'E') {
          const { userStatus } = this.editorRow
          await this.initOrgName()
          this.formData = {
            ...this.editorRow,
            userStatus: userStatus + '',
            userType: this.editorRow.userType === '0' ? '' : this.editorRow.userType,
            editUserType: this.editorRow.userType === '0' ? '' : this.editorRow.userType,
            editOrganizationId: this.editorRow.organizationId === '0' ? '' : this.editorRow.organizationId
          }
          // 编辑前的数据  用作校验
          this.preData = this.editorRow
          this.$nextTick(() => {
            this.$refs.formData.clearValidate()
          })
        } else {
          this.preData = Object.create(null)
        }
      } catch (error) {}
    },
    // 初始化机构名称
    async initOrgName () {
      if (this.editorRow.organizationId && this.editorRow.userType) {
        const { data: options } = await getOrgConfigsByOrgType({
          orgType: this.editorRow.userType
        })
        this.orgOptions = options || []
      }
    },

    async userTypeChange (key) {
      try {
        const { data: options } = await getOrgConfigsByOrgType({
          orgType: key
        })
        this.orgOptions = options || []
        this.$set(this.formData, 'organizationId', '')
        this.$set(this.formData, 'oaUserName', '')
        this.$nextTick(() => {
          this.$refs.formData.clearValidate(['organizationId', 'oaUserName'])
        })
      } catch (error) {}
    },

    handleSubmit: async function () {
      try {
        await this.validate()

        this.model === 'C' ? this.addUser() : this.editor()
      } catch (error) {
        this.warning('请完善表格信息')
      }
    },

    validate () {
      return this.$refs.formData.validate()
    },

    // 编辑成功得回调
    emitEditSuccess () {
      this.$emit('refresh')
      this.visible = false
    },
    /**
     * 新增用户
     */
    addUser: function () {
      if (this.formData.organizationId) {
        this.orgOptions.forEach((item) => {
          if (this.formData.organizationId === item.keyId) {
            this.formData.orgName = item.enterpriseName
          }
        })
      }

      createUserByBiz(this.formData)
        .then((res) => {
          this.success('新增成功')
          this.emitEditSuccess()
        })
        .catch(() => {
          this.error('新增用户失败')
        })
    },
    // 编辑用户
    editor: function () {
      if (this.formData.organizationId) {
        this.orgOptions.forEach((item) => {
          if (this.formData.organizationId === item.keyId) {
            this.formData.orgName = item.enterpriseName
          }
        })
      }
      updateUserByBiz(this.formData.userId, this.formData)
        .then((res) => {
          this.changeBaseUserInfo()
          this.success('保存成功')
          this.emitEditSuccess()
        })
        .catch(() => {
          this.error('保存失败')
        })
    },
    changeBaseUserInfo () {
      if (this.formData.userId === this.$store.state.user.operateId) {
        this.$store.dispatch('init/changeUserInfo')
      }
    }
  }
}
</script>
