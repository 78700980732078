import { checkInputExit, checkInputExitOa, checkInputExitPhone } from '@/api/sys/user'
import BaseSelect from '@/components/common/base-select/new-base-select.vue'
import { getDictLists } from '@/filters/fromDict.js'
import { validatePasswordInput } from './validate'
// 增加form
const disableKey = '20'
// 资金方
export const bankKey = '21'
// 供应商
export const supplierKey = '22'

export const editUserFormConfig = (context) => {
  const isEdit = context.model === 'E'
  const span = 8
  const userType = context.formData.editUserType
  const organizationId = context.formData.editOrganizationId
  return [
    {
      label: '机构类型:',
      prop: 'userType',
      rules: [
        {
          required: true,
          message: '请选择机构类型',
          trigger: ['blur', 'change']
        }
      ],
      on: {
        change: context.userTypeChange
      },
      attrs: {
        disabled: isEdit && userType === supplierKey,
        clearable: true,
        options: getDictLists('USER_TYPE'),
        selectedField: ['dictId', 'dictName'],
        placeholder: '请选择'
      },
      span,
      tag: BaseSelect
    },
    {
      label: '机构名称:',
      prop: 'organizationId',
      rules: [
        {
          required: disableKey !== context.formData.userType,
          message: '请选择机构名称',
          trigger: ['blur', 'change']
        }
      ],
      tag: BaseSelect,
      attrs: {
        selectedField: context.optionSelectedKeys,
        disabled: disableKey === context.formData.userType || (context.model === 'E' && organizationId !== ''),
        options: context.orgOptions,
        filterable: true,
        clearable: true,
        placeholder: '请选择所属机构'
      },
      span
    },
    {
      label: '用户名:',
      prop: 'userName',
      rules: [
        {
          required: true,
          message: '请输入正确的用户名',
          trigger: ['blur', 'change']
        },
        {
          min: 4,
          max: 16,
          message: '用户名长度需在4-16之间',
          trigger: ['blur', 'change']
        }
        // { validator: validateUserName(context, isEdit), trigger: 'blur' }
      ],
      attrs: {
        disabled: isEdit,
        clearable: true,
        placeholder: '请输入用户名'
      },
      span
    },
    {
      label: '昵称:',
      prop: 'nickName',
      rules: [
        {
          required: true,
          message: '请输入正确的昵称',
          trigger: ['blur', 'change']
        }
      ],
      attrs: {
        maxlength: 16,
        placeholder: '请输入昵称'
      },
      span
    },

    {
      label: '邮箱:',
      prop: 'email',
      rules: [
        {
          required: false,
          message: '请输入邮箱',
          trigger: ['blur', 'change']
        },
        { validator: validateEmail(context, isEdit), trigger: 'blur' }
      ],
      attrs: {
        placeholder: '请输入邮箱'
      },
      span
    },
    {
      label: '手机号:',
      prop: 'mobile',
      rules: [
        {
          required: true,
          message: '请输入手机号',
          trigger: ['blur', 'change']
        },
        { validator: validateMobile(context, isEdit), trigger: 'blur' }
      ],
      attrs: {
        disabled: isEdit,
        maxlength: 11,
        placeholder: '请输入手机号'
      },
      span
    },
    {
      label: '用户状态:',
      prop: 'userStatus',
      rules: [
        {
          required: true,
          message: '请选择用户状态',
          trigger: ['blur', 'change']
        }
      ],
      attrs: {
        options: getDictLists('USER_STATUS'),
        selectedField: ['dictId', 'dictName'],
        placeholder: '请选择用户状态'
      },
      tag: BaseSelect,
      isHidden: !isEdit,
      span
    },
    {
      label: '密码:',
      prop: 'password',
      rules: [
        { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
        { validator: validatePasswordInput, trigger: ['blur', 'change'] }
      ],
      attrs: {
        type: 'password',
        placeholder: '请输入密码',
        maxlength: '15'

      },
      span,
      isHidden: isEdit
    },
    {
      label: '确认密码:',
      prop: 'rePassword',
      rules: [
        {
          required: true,
          message: '请输入确认密码',
          trigger: ['blur', 'change']
        },
        { validator: validateRePassword(context), trigger: ['blur', 'change'] }
      ],
      attrs: {
        type: 'password',
        placeholder: '请输入确认密码',
        maxlength: '15'

      },
      span,
      isHidden: isEdit
    },
    {
      label: 'OA用户名:',
      prop: 'oaUserName',
      rules: [
        {
          validator: validateOaUserName(context, isEdit),
          trigger: ['blur', 'change']
        }
      ],
      attrs: {
        disabled: disableKey !== context.formData.userType,
        placeholder: '请输入OA用户名',
        maxlength: 16
      },
      span
    },
    {
      label: '用户描述:',
      prop: 'userDesc',
      attrs: {
        type: 'textarea',
        maxlength: '200',
        row: 4,
        placeholder: ''
      },
      span: 24
    }
  ]
}

export const validateRePassword = (context) => {
  return (rule, value, callback) => {
    if (
      value &&
      context.formData.password &&
      value !== context.formData.password
    ) {
      callback(new Error('两次输入密码不一致'))
    } else {
      callback()
    }
  }
}

/**
 * OA 用户名称校验
 * @param {*} context
 * @param {*} isEditor
 * @returns
 */
export const validateOaUserName = (context, isEditor) => {
  const {
    preData: { oaUserName }
  } = context
  return (rule, value, callback) => {
    // const reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_]){3,15}$/
    if (disableKey !== context.formData.userType) {
      callback()
    }
    // 没有输入 直接返回
    if (!value) {
      callback()
    } else {
      if (value.length > 16 || value.length < 4) {
        return callback(new Error('OA用户名长度4-16位'))
      }
      // if (reg.test(value)) {
      if (isEditor && oaUserName === value) {
        callback()
      } else {
        checkInputExitOa({ oaUserName: value })
          .then((res) => {
            if (res.data) {
              callback(new Error('该OA用户已存在'))
            } else {
              callback()
            }
          })
          .catch(() => {
            callback()
          })
      }
      // } else {
      //   callback(new Error('用户名必须以字母开头且只能输入字母、数字和下划线'))
      // }
    }
  }
}
/**
 * 用户名称输入校验 重复 格式
 * @param {} rule
 * @param {*} value
 * @param {*} callback
 */
export const validateUserName = (context, isEditor) => {
  const {
    preData: { userName }
  } = context
  return (rule, value, callback) => {
    const reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_]){3,15}$/
    if (value && reg.test(value)) {
      if (isEditor && userName === value) {
        callback()
      } else {
        checkInputExit({ target: value })
          .then((res) => {
            if (res.data) {
              callback(new Error('该用户已存在'))
            } else {
              callback()
            }
          })
          .catch(() => {
            callback()
          })
      }
    } else {
      callback(new Error('用户名必须以字母开头且只能输入字母、数字和下划线'))
    }
  }
}
/**
 * 邮箱认证
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export const validateEmail = (context, isEditor) => {
  // console.log(context, isEditor, '@@@')
  const { preData: { email } } = context

  return (rule, value, callback) => {
    console.log(value)
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (value) {
      if (reg.test(value)) {
        // 编辑邮箱一致不做校验
        if (isEditor && email === value) {
          callback()
        } else {
          checkInputExit({ target: value }).then((res) => {
            if (res.data) {
              callback(new Error('该邮箱已被注册'))
            } else {
              callback()
            }
          })
        }
      } else {
        callback(new Error('请输入正确的邮箱'))
      }
    } else {
      callback()
    }
  }
}
/**
 * 电话号码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export const validateMobile = (context, isEditor) => {
  const {
    preData: { mobile }
  } = context
  return (rule, value, callback) => {
    const reg = /^1[3456789]\d{9}$/

    if (value && reg.test(value)) {
      if (isEditor && mobile === value) {
        callback()
      } else {
        checkInputExitPhone({ phone: value }).then((res) => {
          if (res.data) {
            callback(new Error('该手机号已被注册'))
          } else {
            callback()
          }
        })
      }
    } else {
      callback(new Error('请输入正确格式的手机号'))
    }
  }
}
